.AnalyticsTable .backgroundCell {
  background: rgb(215, 215, 215);
  position: absolute;
  top: -1px;
  bottom: -0.5px;
  right: -0.5px;
  left: -1px;
  z-index:-1;
}

.AnalyticsTable {
  border-collapse: collapse;   
  padding: 0px;
  position: relative;
  border:none;
}

.AnalyticsTable td, .AnalyticsTable th {
  padding: 0px;
  position: relative;
  border: none;
  vertical-align: top;    
}

.AnalyticsTable th .header {
  outline: 0.5px solid rgb(215, 215, 215);
  outline-offset: -0.5px;     
  border: none;
  border-top-width: 0;
  border-top: none;
  border: 0px solid rgb(215, 215, 215);
}

.AnalyticsTable td {
/*  border: 0.5px solid rgb(210, 228, 210);    
  border-right: 0.25px solid rgb(210, 228, 210);    
  border-left: 0.25px solid rgb(210, 228, 210);    
*/
  background-color: inherit;
  outline: 0.5px solid rgb(111, 111, 111, 0.36);
  border: none;
  border: 0px;
  outline-offset: -0.5px;   
}


.AnalyticsTable .selecterd {
  outline: 1px solid orange;
  outline-offset: -1px;   
}

.AnalyticsTable tbody tr {
  /*border: 0.5px solid;*/
}

.AnalyticsTable tbody tr:nth-child(even){
  background-color: rgb(248,248,248);
  /*border-color: rgba(210, 228, 210);  


  border-top-color: rgba(210, 228, 210);
  border-bottom-color: rgba(210, 228, 210);
  border-right-color: rgba(210, 228, 210);
  border-left-color: rgba(210, 228, 210);*/
}

.AnalyticsTable tbody tr:nth-child(odd){
  background-color: #ffffff;

/*
  border-top-color: rgba(210, 228, 210);
  border-bottom-color: rgba(210, 228, 210);
  border-right-color: rgba(210, 228, 210);
  border-left-color: rgba(210, 228, 210);*/
}

.AnalyticsTable .innerTable tbody tr:nth-child(odd) .main {
  background-color: #ffffff;
  /*border-color: rgba(210, 228, 210);  */
}

.AnalyticsTable .innerTable tbody tr:nth-child(even)  .main{
  background-color: rgb(248,248,248);
}

.AnalyticsTable .rows-separator {
  height: 12px;
  border: none;
  outline-style: none;
  outline-color: rgb(215, 215, 215);
  background-color: transparent;
}
/*
.AnalyticsTable td {
  border-color: rgba(210, 228, 210);  
}*/

.AnalyticsTable .FirstColumn {
  /*border: 0.5px solid rgb(215, 215, 215);
  border-collapse: collapse;   
  padding: 0px;
*/

  border: none;
  border: 0px;

  outline: 0.5px solid rgb(215, 215, 215);
  outline-offset: -0.5px;   
}

.AnalyticsTable .column {
    z-index: 500;    
} 

.AnalyticsTable .innerTable {
  /*z-index: 1100;*/
}

          

.AnalyticsTable .innerTable .column {
  /*z-index: 1500;      */
}

/*.AnalyticsTable .shadow-full {
  box-shadow: 2px 2px 2px 2px #888888;
}

*/
/*
.AnalyticsTable tbody .expanded  td {
  z-index: 2500;
  border-style: none;

  outline-style: solid;
  outline-width: 0.5px;
  outline-color: gray;
  outline-offset: -0.5px;
}*/

.AnalyticsTable .shadow-full:before {
    box-shadow: -15px 0 15px -15px inset;
    content: " ";
    height: 100%;
    position: absolute;
    top: 0;
    left: -15px;
    width: 15px;
}
.AnalyticsTable .shadow-full:after {
    box-shadow: 15px 0 15px -15px inset;
    content: " ";
    height: 100%;
    position: absolute;
    top: 0;

    right: -15px;
    width: 15px;
}

.AnalyticsTable .column-shadow-right {
    z-index: 1001;    
} 

.AnalyticsTable .column-shadow-left {
    z-index: 1001;
} 

.AnalyticsTable .shadow-right {
    position: relative;
} 

.AnalyticsTable .shadow-right:before {
    box-shadow: 0px 0 15px -15px inset;
    content: " ";
    height: 100%;
    position: absolute;
    top: 0;
    
    width: 15px;
}
.AnalyticsTable .shadow-right:after {
    box-shadow: 15px 0 15px -15px inset;
    content: " ";
    height: 100%;
    position: absolute;
    top: 0;

    right: -15px;
    width: 15px;
}

.AnalyticsTable .shadow-left {
    position: relative;
    /* z-index: 1499; */
} 
.AnalyticsTable .shadow-left:before {
    box-shadow: -15px 0 15px -15px inset;
    content: " ";
    height: 100%;
    position: absolute;
    top: 0;
    
    left: -15px;
    width: 15px;
}
.AnalyticsTable .shadow-left:after {
    box-shadow: 0px 0 0px 0px inset;
    content: " ";
    height: 100%;
    position: absolute;
    top: 0;   

    width: 15px;
}

.AnalyticsTable tbody .selectedCell {
    outline-color: #f27d22;
    outline-style: solid;
    outline-width: 2px;
    /*margin-left: 1px;*/
    outline-offset: -2px;
    /*border-color: #f27d22;
    border-style: solid;
    border-width: 2px;*/
  }

.AnalyticsTable tbody .selectedCell .cellContent {
    padding: 1px;
  }


/*
.AnalyticsTable .column-shadow-top {
    z-index: 1001;    
} 
.AnalyticsTable .shadow-right-top {
    position: relative;
} 


.AnalyticsTable .column-top {
    z-index: 1001;    
} 

.AnalyticsTable .shadow-top {
    position: relative;
    z-index: 499;
} 
.AnalyticsTable .shadow-top:before {
    box-shadow: 0px -15px 15px -15px inset;
    content: " ";
    width: 100%;
    position: absolute;
    right: 0;
    height: 15px;

    top: -15px;    
}
.AnalyticsTable .shadow-top:after {
    box-shadow: 0 0px 15px -15px inset;
    content: " ";
    width: 100%;
    position: absolute;
    right: 0;
    height: 15px;

    bottom: -15px;
}

.AnalyticsTable .shadow-location-column {
    z-index: 1020;
} 

.AnalyticsTable .shadow-bottom {
    position: relative;
    z-index: 499;
} 

.AnalyticsTable .shadow-bottom:before {
    box-shadow: 0px 0px 15px -15px inset;
    content: " ";
    width: 100%;
    position: absolute;
    right: 0;
    height: 15px;

    top: -15px;    
}
.AnalyticsTable .shadow-bottom:after {
    box-shadow: 0 15px 15px -15px inset;
    content: " ";
    width: 100%;
    position: absolute;
    right: 0;
    height: 15px;

    bottom: -15px;
}
*/
.AnalyticsTable .innerTable tbody tr .NotActiveCell {
  opacity: 0.5;
}

.AnalyticsTable .innerTable tbody tr:nth-child(even) .EmptyCell {
  background: repeating-linear-gradient(
    -65deg,
    rgb(248,248,248) 0px,
    rgb(248,248,248) 5px,
    rgba(209, 209, 209) 5px,
    rgba(209, 209, 209)  6px
  );

  /*border: 0.5px solid rgb(215, 215, 215);  */
  height: 40px;
}

.AnalyticsTable .innerTable tbody tr:nth-child(odd) .EmptyCell {
  background: repeating-linear-gradient(
    -65deg,
    white 0px,
    white 5px,
    rgba(209, 209, 209) 5px,
    rgba(209, 209, 209)  6px
  );

  /*border: 0.5px solid rgb(215, 215, 215);  */
  height: 40px;
}

  
.AnalyticsTable .header {
  border: none;
}

.no-border {
  border-style: none;
}


.AnalyticsTable .backgroundCell {
    background: rgb(215, 215, 215);
    position: absolute;
    top: -1px;
    bottom: -0.5px;
    right: -0.5px;
    left: -1px;
    z-index:-1;
}


.AnalyticsTable tbody tr th.expended {
  z-index: 1105;
}
/*
.options div:hover { 
  background-color: yellow;
}*/

.option span:hover { 
  color: #f27d22;
}

/*
.AnalyticsTable tbody tr td.CompletedCell .backgroundCell {
  background-color: rgba(36, 197, 120);
}

.AnalyticsTable tbody tr td.CompletedCell {
  border-color: rgba(36, 197, 120);
}
*/
/*.AnalyticsTable tbody tr td.CompletedCell div{
  background-color: #32e88c;
  border-color: rgba(36, 197, 120);
}
*/
/*.AnalyticsTable tbody tr .CompletedCell {
  background-color: #32e88c;
  border-color: rgba(36, 197, 120);
}
*/
/*
.AnalyticsTable .CompletedCell .main {
  background-color: #32e88c;
  border-color: rgba(36, 197, 120);
}

.AnalyticsTable td.CompletedCell .main {
  background-color: #32e88c;
  border-color: rgba(36, 197, 120);  
}*/


.blur {
  filter: blur(6px);
}

.menuShadow {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
/*box-shadow: -10px 0px 10px 1px #aaaaaa;*/

}
